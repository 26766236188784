import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Heading, Subheading } from './Components/Catalyst/heading';
import { Button } from './Components/Catalyst/button';
import { memberService } from './ApiClients';
import { MemberSchema } from './auth-api-client';
import ShowAlert from './ShowAlert';
import LoadingSpinner from './LoadingSpinner';
import { formatPhoneNumber } from './utils/phoneUtils';
import { Divider } from './Components/Catalyst/divider';
import { SidebarLayout } from './Components/Catalyst/sidebar-layout';
import { Navbar } from './Components/Catalyst/navbar';
import AppSidebar from './AppSidebar';

const MemberDetailPage: React.FC = () => {
  const { memberId } = useParams();
  const navigate = useNavigate();
  const [member, setMember] = useState<MemberSchema | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const fetchMember = async () => {
      if (!memberId) return;

      try {
        setLoading(true);
        const response = await memberService.getMemberById({
          memberId: parseInt(memberId),
        });
        setMember(response.data);
      } catch (err) {
        console.error('Error fetching member:', err);
        setError('Failed to fetch member details. Please try again.');
        setShowError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchMember();
  }, [memberId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!member) {
    return (
      <div className="p-4">
        <ShowAlert
          alertType="error"
          alertTitle="Error"
          alertMessage="Member not found"
          isVisible={true}
          onClose={() => {}}
          isInline={true}
        />
      </div>
    );
  }

  return (
    <SidebarLayout
      sidebar={<AppSidebar currentPage="member_detail" />}
      navbar={<Navbar>{/* Your navbar content */}</Navbar>}
    >
      <div className="flex justify-between items-center">
        <Heading level={2}>
          {member.first_name}{' '}
          {member.middle_name ? `${member.middle_name} ` : ''}
          {member.last_name}
        </Heading>
        <div>
          <Button
            color="sky"
            onClick={() => navigate(`/members/${memberId}/edit`)}
            className="mr-2"
          >
            Edit Member
          </Button>
        </div>
      </div>

      {member.preferred_name && (
        <Subheading className="mt-2">
          Preferred name: {member.preferred_name}
        </Subheading>
      )}

      <Divider className="mt-4 mb-6" />

      <ShowAlert
        alertType="error"
        alertTitle="Error"
        alertMessage={error || 'An error occurred'}
        isVisible={showError}
        onClose={() => setShowError(false)}
        timeout={5000}
        isInline={true}
      />

      <div>
        <Heading
          level={4}
          className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
        >
          Contact Information
        </Heading>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {[
            { name: 'Email', value: member.email },
            {
              name: 'Phone',
              value: member.phone ? formatPhoneNumber(member.phone) : '',
            },
            {
              name: 'Primary Contact',
              value: member.is_primary ? 'Yes' : 'No',
            },
          ].map((item) => (
            <div
              key={item.name}
              className="overflow-hidden rounded-lg bg-stone-100 dark:bg-gray-600 px-2 py-5 shadow sm:p-4 transition-colors duration-200"
            >
              <dt className="truncate text-sm font-medium text-gray-900 dark:text-white">
                {item.name}
              </dt>
              <dd className="mt-1 text-medium font-semibold tracking-tight text-gray-900 dark:text-white break-all">
                {item.value || 'N/A'}
              </dd>
            </div>
          ))}
        </dl>
      </div>

      <Divider className="mt-4 mb-6" />

      <div>
        <Heading
          level={4}
          className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
        >
          Member Details
        </Heading>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {[
            { name: 'Status', value: member.is_active ? 'Active' : 'Inactive' },
            {
              name: 'Member Since',
              value: new Date(member.date_created).toLocaleDateString(),
            },
          ].map((item) => (
            <div
              key={item.name}
              className="overflow-hidden rounded-lg bg-stone-100 dark:bg-gray-600 px-2 py-5 shadow sm:p-4 transition-colors duration-200"
            >
              <dt className="truncate text-sm font-medium text-gray-900 dark:text-white">
                {item.name}
              </dt>
              <dd className="mt-1 text-medium font-semibold tracking-tight text-gray-900 dark:text-white break-all">
                {item.value || 'N/A'}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </SidebarLayout>
  );
};

export default MemberDetailPage;
