import React, { useEffect, useState, ReactNode } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './LoginPage';
import ResetPasswordPage from './ResetPasswordPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import { getToken, verifyToken } from './TokenUtils';
import Dashboard from './Dashboard';
import HOAPage from './HOAPage';
import HOADetailPage from './HOADetailPage';
import HomeDetailPage from './HomeDetailPage';
import ViolationPage from './ViolationPage';
import ViolationList from './ViolationList';
import ViolationDetailPage from './ViolationDetailPage';
import LoadingSpinner from './LoadingSpinner';
import SettingsPage from './SettingsPage';
import { ThemeProvider } from './Components/Custom/ThemeProvider';
import MemberDetailPage from './MemberDetailPage';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkAuth = async () => {
      const token = getToken();
      const auth = await verifyToken(token);
      setIsAuthenticated(auth);
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  if (isLoading) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return isAuthenticated ? <>{children}</> : <Navigate to="/login" replace />;
};

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <div className="min-h-screen bg-stone-300 dark:bg-gray-900">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/home" element={<Dashboard currentPage="home" />} />
          <Route path="/hoas" element={<HOAPage currentPage="hoas" />} />
          <Route
            path="/settings"
            element={<SettingsPage currentPage="settings" />}
          />
          <Route
            path="/violations"
            element={<ViolationPage currentPage="violations" />}
          />
          <Route path="/hoa/:hoaId/violations" element={<ViolationList />} />
          <Route
            path="/hoa/:hoaId/home/:homeId/violations"
            element={<ViolationList />}
          />
          <Route
            path="/violations/:violationId"
            element={<ViolationDetailPage />}
          />
          <Route path="/hoa/:id" element={<HOADetailPage />} />
          <Route path="/member/:memberId" element={<MemberDetailPage />} />
          <Route path="/hoa/:hoaId/home/:homeId" element={<HomeDetailPage />} />
          {/* More routes as needed */}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
};

export default App;
