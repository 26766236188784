import {
  AuthApi,
  UserApi,
  CompanyApi,
  Configuration,
  MemberApi,
} from './auth-api-client';
import {
  HoaApi,
  Configuration as HoaConfiguration,
  HomeApi,
} from './hoa-api-client';
import {
  ViolationsApi,
  Configuration as ViolationConfiguration,
} from './vio-api-client';
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import {
  clearAllTokens,
  getRefreshToken,
  getRememberMe,
  getToken,
  refreshAccessToken,
  setToken,
  tokenIsUnexpired,
} from './TokenUtils';

const AUTH_SVC_BASE_URL = window._env_.REACT_APP_AUTH_SVC;
const HOA_SVC_BASE_URL = AUTH_SVC_BASE_URL + '/api/hoa';
const VIO_SVC_BASE_URL = AUTH_SVC_BASE_URL + '/api/vio';

// Initialize the API client
const apiConfig = new Configuration({
  basePath: AUTH_SVC_BASE_URL,
});

const hoaConfig = new HoaConfiguration({
  basePath: HOA_SVC_BASE_URL,
});

const violationConfig = new ViolationConfiguration({
  basePath: VIO_SVC_BASE_URL,
});

const memberConfig = new Configuration({
  basePath: AUTH_SVC_BASE_URL,
});

// Create separate axios instances for each service
const authAxiosInstance: AxiosInstance = axios.create({
  baseURL: AUTH_SVC_BASE_URL,
});

const hoaAxiosInstance: AxiosInstance = axios.create({
  baseURL: HOA_SVC_BASE_URL,
});

const userAxiosInstance: AxiosInstance = axios.create({
  baseURL: AUTH_SVC_BASE_URL,
});

const companyAxiosInstance: AxiosInstance = axios.create({
  baseURL: AUTH_SVC_BASE_URL,
});

const violationAxiosInstance: AxiosInstance = axios.create({
  baseURL: VIO_SVC_BASE_URL,
});

const memberAxiosInstance: AxiosInstance = axios.create({
  baseURL: AUTH_SVC_BASE_URL,
});

export const authService = new AuthApi(apiConfig);
export const userService = new UserApi(
  apiConfig,
  AUTH_SVC_BASE_URL,
  userAxiosInstance
);
export const companyService = new CompanyApi(
  apiConfig,
  AUTH_SVC_BASE_URL,
  companyAxiosInstance
);

const saveCurrentLocation = () => {
  // Only save if we're not already on the login page
  if (!window.location.pathname.includes('/login')) {
    const location = {
      pathname: window.location.pathname,
      search: window.location.search,
      hash: window.location.hash,
    };
    sessionStorage.setItem('returnTo', JSON.stringify(location));
  }
};

// Common request interceptor
const requestInterceptor = async (
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  let token = getToken();

  if (token) {
    const isUnexpired = await tokenIsUnexpired(token);

    if (!isUnexpired) {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        const newToken = await refreshAccessToken(refreshToken);
        if (newToken) {
          token = newToken;
          setToken(newToken, !!getRememberMe());
        } else {
          // Refresh failed, clear tokens and redirect to login
          clearAllTokens();
          saveCurrentLocation();
          window.location.href = '/login';
          throw new axios.Cancel(
            'Operation canceled due to token refresh failure.'
          );
        }
      } else {
        // No refresh token available, clear tokens and redirect to login
        clearAllTokens();
        saveCurrentLocation();
        window.location.href = '/login';
        throw new axios.Cancel(
          'Operation canceled due to missing refresh token.'
        );
      }
    }

    config.headers = config.headers || {};
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};

// Common response interceptor to redirect 401 to login page
const responseInterceptor = (error: any) => {
  if (error.response && error.response.status === 401) {
    saveCurrentLocation();
    clearAllTokens();
    window.location.href = '/login';
  }
  return Promise.reject(error);
};

// add api clients as needed to this loop to add both interceptors
[
  hoaAxiosInstance,
  userAxiosInstance,
  violationAxiosInstance,
  companyAxiosInstance,
  memberAxiosInstance,
].forEach((instance) => {
  instance.interceptors.request.use(requestInterceptor, (error) =>
    Promise.reject(error)
  );
  instance.interceptors.response.use(
    (response) => response,
    responseInterceptor
  );
});

// Use the axios instance with the HOA API
export const hoaService = new HoaApi(
  hoaConfig,
  HOA_SVC_BASE_URL,
  hoaAxiosInstance
);

export const homeService = new HomeApi(
  hoaConfig,
  HOA_SVC_BASE_URL,
  hoaAxiosInstance
);

export const violationService = new ViolationsApi(
  violationConfig,
  VIO_SVC_BASE_URL,
  violationAxiosInstance
);

export const memberService = new MemberApi(
  memberConfig,
  AUTH_SVC_BASE_URL,
  memberAxiosInstance
);

// Export the axios instances for use in components
export {
  authAxiosInstance,
  hoaAxiosInstance,
  violationAxiosInstance,
  companyAxiosInstance,
  memberAxiosInstance,
};
