import React, { useState, useEffect } from 'react';
import { Heading } from './Components/Catalyst/heading';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './Components/Catalyst/table';
import { Input, InputGroup } from './Components/Catalyst/input';
import {
  MagnifyingGlassIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Button } from './Components/Catalyst/button';
import { Dialog, DialogTitle, DialogBody } from './Components/Catalyst/dialog';
import {
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from './Components/Catalyst/fieldset';
import { useNavigate } from 'react-router-dom';
import { Divider } from './Components/Catalyst/divider';
import { CreateMemberSchema, MemberSchema } from './auth-api-client';

import ShowAlert from './ShowAlert';
import LoadingSpinner from './LoadingSpinner';
import { formatPhoneNumber, stripPhoneFormatting } from './utils/phoneUtils';
import { memberService } from './ApiClients';

interface FormErrors {
  email?: string;
  phone?: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  preferred_name?: string;
}

const initialMemberState: CreateMemberSchema = {
  email: '',
  phone: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  preferred_name: '',
  company_id: 0,
  is_primary: false,
  user_type: 'MEMBER',
  password: '',
};

interface TouchedFields {
  [key: string]: boolean;
}

interface MemberListProps {
  hoaId: number;
}

const MemberList: React.FC<MemberListProps> = ({ hoaId }) => {
  const navigate = useNavigate();
  const [members, setMembers] = useState<MemberSchema[]>([]);
  const [filteredMembers, setFilteredMembers] = useState<MemberSchema[]>([]);
  const [loading, setLoading] = useState(true);
  const [fetchErrorTitle, setFetchErrorTitle] = useState<string>('');
  const [fetchError, setFetchError] = useState<string>('');
  const [showFetchError, setShowFetchError] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newMember, setNewMember] =
    useState<CreateMemberSchema>(initialMemberState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [touchedFields, setTouchedFields] = useState<TouchedFields>({});
  const [errorTitle, setErrorTitle] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [showCreateMemberError, setShowCreateMemberError] = useState(false);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        setLoading(true);
        const response = await memberService.getAllMembersForHOA({ hoaId });
        setMembers(response.data);
        setFilteredMembers(response.data);
      } catch (err) {
        console.error('Error fetching members:', err);
        let errorMessage =
          'An unexpected error occurred while fetching members.';
        if (err instanceof Error) {
          errorMessage = err.message;
        }
        setFetchErrorTitle('Failed to Load Members');
        setFetchError(errorMessage);
        setShowFetchError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchMembers();
  }, [hoaId]);

  useEffect(() => {
    const filtered = members.filter(
      (member) =>
        member.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        member.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        member.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMembers(filtered);
  }, [searchTerm, members]);

  const handleRowClick = (member: MemberSchema) => {
    navigate(`/member/${member.id}`);
  };

  const handleAddMember = () => {
    setIsDialogOpen(true);
  };

  const resetForm = () => {
    setNewMember(initialMemberState);
    setFormErrors({});
    setTouchedFields({});
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    resetForm();
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const validateField = (
    name: keyof CreateMemberSchema,
    value: string | number | boolean | null | undefined
  ): string | undefined => {
    const stringValue = typeof value === 'number' ? value.toString() : value;

    switch (name) {
      case 'email':
        return !stringValue
          ? 'Email is required'
          : !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(stringValue.toString())
            ? 'Invalid email format'
            : undefined;
      case 'phone':
        const cleanedPhone = stripPhoneFormatting(
          stringValue?.toString() || ''
        );
        return !cleanedPhone
          ? 'Phone number is required'
          : !/^\d{10,11}$/.test(cleanedPhone)
            ? 'Phone number must be 10 or 11 digits'
            : undefined;
      case 'first_name':
        return !stringValue ? 'First name is required' : undefined;
      case 'last_name':
        return !stringValue ? 'Last name is required' : undefined;
      default:
        return undefined;
    }
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof CreateMemberSchema, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name as keyof FormErrors] = error;
      } else {
        delete newErrors[name as keyof FormErrors];
      }
      return newErrors;
    });
  };

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    (Object.keys(newMember) as Array<keyof CreateMemberSchema>).forEach(
      (key) => {
        const error = validateField(key, newMember[key]);
        if (error) {
          errors[key as keyof FormErrors] = error;
        }
      }
    );
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      const cleanedPhone = stripPhoneFormatting(value);
      setNewMember((prev) => ({ ...prev, [name]: cleanedPhone }));
      setTouchedFields((prev) => ({ ...prev, [name]: true }));

      const error = validateField(
        name as keyof CreateMemberSchema,
        cleanedPhone
      );
      setFormErrors((prev) => {
        const newErrors = { ...prev };
        if (error) {
          newErrors[name as keyof FormErrors] = error;
        } else {
          delete newErrors[name as keyof FormErrors];
        }
        return newErrors;
      });
      return;
    }

    setNewMember((prev) => ({ ...prev, [name]: value }));
    setTouchedFields((prev) => ({ ...prev, [name]: true }));

    const error = validateField(name as keyof CreateMemberSchema, value);
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (error) {
        newErrors[name as keyof FormErrors] = error;
      } else {
        delete newErrors[name as keyof FormErrors];
      }
      return newErrors;
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setNewMember((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSaveMember = async () => {
    if (validateForm()) {
      try {
        const memberData = {
          ...newMember,
          company_id: hoaId,
        };
        const response = await memberService.createMember({
          createMemberSchema: memberData,
        });
        setMembers((prevMembers) => [...prevMembers, response.data]);
        handleDialogClose();
      } catch (err) {
        console.error('Error creating member:', err);
        let errorMessage = 'An unexpected error occurred. Please try again.';
        if (err instanceof Error) {
          errorMessage = err.message;
        }
        setErrorTitle('Failed to create member. Please review your data.');
        setError(errorMessage);
        setShowCreateMemberError(true);
      }
    }
  };

  const isFormValid = () => {
    if (Object.keys(formErrors).length > 0) {
      return false;
    }

    const requiredFields = ['email', 'phone', 'first_name', 'last_name'];

    return requiredFields.every((field) => {
      const value = newMember[field as keyof CreateMemberSchema];
      return value !== undefined && value !== '';
    });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0 mb-4">
        <Heading
          className="text-base font-semibold leading-6 text-gray-900 dark:text-white"
          level={4}
        >
          Member List
        </Heading>
        <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-4">
          <Button onClick={handleAddMember} color="sky">
            <PlusIcon className="h-5 w-5 mr-2" />
            Add Member
          </Button>
          <div className="w-full sm:w-64 bg-stone-300 dark:bg-gray-800">
            <InputGroup className="[&>[data-slot=icon]]:text-zinc-500 dark:[&>[data-slot=icon]]:text-white">
              <MagnifyingGlassIcon data-slot="icon" />
              <Input
                type="search"
                placeholder="Search by name or email..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </InputGroup>
          </div>
        </div>
      </div>

      <ShowAlert
        alertType="error"
        alertTitle={fetchErrorTitle}
        alertMessage={fetchError}
        isVisible={showFetchError}
        onClose={() => setShowFetchError(false)}
        timeout={5000}
        isInline={true}
      />

      <div className="bg-stone-100 dark:bg-gray-600 rounded-lg shadow transition-colors duration-200 overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <div className="max-h-[500px] overflow-y-auto relative">
                  <Table>
                    <TableHead className="sticky top-0 z-10 bg-stone-100 dark:bg-gray-600">
                      <TableRow>
                        <TableHeader className="text-base font-semibold">
                          Name
                        </TableHeader>
                        <TableHeader className="text-base font-semibold">
                          Email
                        </TableHeader>
                        <TableHeader className="text-base font-semibold">
                          Phone
                        </TableHeader>
                        <TableHeader className="text-base font-semibold">
                          Status
                        </TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredMembers.map((member) => (
                        <TableRow
                          key={member.id}
                          onClick={() => handleRowClick(member)}
                          className="cursor-pointer hover:bg-stone-200 dark:hover:bg-zinc-700 transition-colors duration-150"
                        >
                          <TableCell>
                            {`${member.first_name} ${member.middle_name ? member.middle_name + ' ' : ''}${member.last_name}`}
                            {member.preferred_name &&
                              ` (${member.preferred_name})`}
                          </TableCell>
                          <TableCell>{member.email}</TableCell>
                          <TableCell>
                            {member.phone
                              ? formatPhoneNumber(member.phone)
                              : ''}
                          </TableCell>
                          <TableCell>
                            <span
                              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                member.is_active
                                  ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                                  : 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'
                              }`}
                            >
                              {member.is_active ? 'Active' : 'Inactive'}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        className="w-full max-w-2xl mx-auto"
      >
        <div className="relative flex flex-col h-full">
          <div className="p-2 md:p-4">
            <DialogTitle className="text-xl font-semibold">
              New Member
            </DialogTitle>
            <button
              onClick={handleDialogClose}
              className="absolute right-4 top-4 p-2 hover:bg-zinc-100 dark:hover:bg-zinc-700 rounded-full transition-colors duration-150"
              aria-label="Close dialog"
            >
              <XMarkIcon className="h-5 w-5 text-zinc-600 dark:text-zinc-100" />
            </button>

            <Divider className="my-4" />

            <div className="mb-4">
              <ShowAlert
                alertType="error"
                alertTitle={errorTitle ?? 'Something went wrong'}
                alertMessage={error ?? 'Please try again'}
                isVisible={showCreateMemberError}
                onClose={() => setShowCreateMemberError(false)}
                timeout={5000}
                isInline={true}
              />
            </div>

            <DialogBody>
              <Fieldset>
                <FieldGroup>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Field>
                      <Label htmlFor="first_name">First Name*</Label>
                      <Input
                        id="first_name"
                        name="first_name"
                        value={newMember.first_name}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter first name"
                        required
                        data-invalid={
                          touchedFields.first_name && formErrors.first_name
                        }
                      />
                      {touchedFields.first_name && formErrors.first_name && (
                        <ErrorMessage>{formErrors.first_name}</ErrorMessage>
                      )}
                    </Field>

                    <Field>
                      <Label htmlFor="last_name">Last Name*</Label>
                      <Input
                        id="last_name"
                        name="last_name"
                        value={newMember.last_name}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter last name"
                        required
                        data-invalid={
                          touchedFields.last_name && formErrors.last_name
                        }
                      />
                      {touchedFields.last_name && formErrors.last_name && (
                        <ErrorMessage>{formErrors.last_name}</ErrorMessage>
                      )}
                    </Field>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <Field>
                      <Label htmlFor="middle_name">Middle Name</Label>
                      <Input
                        id="middle_name"
                        name="middle_name"
                        value={newMember.middle_name || ''}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter middle name"
                      />
                    </Field>

                    <Field>
                      <Label htmlFor="preferred_name">Preferred Name</Label>
                      <Input
                        id="preferred_name"
                        name="preferred_name"
                        value={newMember.preferred_name || ''}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        placeholder="Enter preferred name"
                      />
                    </Field>
                  </div>

                  <Field>
                    <Label htmlFor="email">Email*</Label>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      value={newMember.email}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter email address"
                      required
                      data-invalid={touchedFields.email && formErrors.email}
                    />
                    {touchedFields.email && formErrors.email && (
                      <ErrorMessage>{formErrors.email}</ErrorMessage>
                    )}
                  </Field>

                  <Field>
                    <Label htmlFor="phone">Phone*</Label>
                    <Input
                      id="phone"
                      name="phone"
                      type="tel"
                      value={
                        newMember.phone
                          ? formatPhoneNumber(newMember.phone)
                          : ''
                      }
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      placeholder="Enter phone number"
                      required
                      data-invalid={touchedFields.phone && formErrors.phone}
                    />
                    {touchedFields.phone && formErrors.phone && (
                      <ErrorMessage>{formErrors.phone}</ErrorMessage>
                    )}
                  </Field>

                  <Field>
                    <div className="flex items-center mt-2">
                      <input
                        id="is_primary"
                        type="checkbox"
                        name="is_primary"
                        checked={newMember.is_primary}
                        onChange={handleCheckboxChange}
                        className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                      />
                      <Label htmlFor="is_primary" className="ml-2">
                        Primary Contact
                      </Label>
                    </div>
                  </Field>
                </FieldGroup>
              </Fieldset>
            </DialogBody>
          </div>

          <div className="sticky bottom-0 mt-auto border-t border-gray-200 dark:border-gray-700 bg-stone-100 dark:bg-gray-800 p-4 md:p-6">
            <div className="flex flex-col space-y-3">
              <Button
                onClick={handleSaveMember}
                color="sky"
                disabled={!isFormValid()}
                className="w-full disabled:opacity-50"
              >
                Save
              </Button>
              <Button
                onClick={handleDialogClose}
                color="zinc"
                className="w-full"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default MemberList;
